import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ModularContent from '../components/modularContent';

const IndexPage = ({ data }) => {
  const page = data.datoCmsPage;
  return (
    <Layout>
      {page.seo && <SEO title={page.seo.title} description={page.seo.description} />}
      <h1>{page.title}</h1>
      <ModularContent page={page} />
    </Layout>
  );
};

export const query = graphql`
  query Home {
    datoCmsPage(slug: { eq: "" }) {
      title
      seo {
        description
        title
      }
      blocks {
        ... on DatoCmsTextTitle {
          title
          titleField {
            hex
          }
          textColor {
            hex
          }
          showTitle
          internal {
            type
          }
          textFieldNode {
            id
            internal {
              content
            }
          }
        }
        ... on DatoCmsTextImage {
          image {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
          internal {
            type
          }
          textFieldNode {
            internal {
              content
            }
          }
        }
        ... on DatoCmsImage {
          image {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
          internal {
            type
          }
        }
        ... on DatoCmsAccordion {
          internal {
            type
          }
          title
          titleColor {
            hex
          }
          textColor {
            hex
          }
          textFieldNode {
            internal {
              content
            }
          }
        }
        ... on DatoCmsVideo {
          internal {
            type
          }
          title
          youtubeEmbedUrl
        }
        ... on DatoCmsFacebookLink {
          id
          internal {
            type
          }
          facebookGroupUrl
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default IndexPage;
